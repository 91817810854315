<template>
    <div class="ml-0">
        <h3 class="is-size-4 has-text-left has-text-success">Blocks Order #{{ quotation.id }}</h3>
        <h4 class="is-size-5 has-text-left">Status: {{ quotation.status }}</h4>
        <h4 class="is-size-5 has-text-left">Total amount: UGX  {{ (1 * quotation.amount_due).toLocaleString() }}</h4>
        <h4 class="is-size-6 has-text-left">(Inclusive 18% VAT)</h4>
        <h4 class="is-size-5 has-text-left">Quotation</h4>

        <table class="table">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                </tr>
            </thead>

            <tbody class="">
                <tr
                    v-for="item in quotation.items"
                    v-bind:key="item.id"
                >
                    <td class="has-text-left">{{ item.product_name }}</td>
                    <td class="has-text-right">{{ item.unit_price }}</td>
                    <td class="has-text-center">{{ item.quantity }}</td>
                    <td class="has-text-right">{{ (item.total).toLocaleString() }}</td>
                </tr>
            </tbody>
        </table>
        <div class="rootcustom">
            <button class="is-success button" @click="isOpen = true">Upload Payment Slip</button>
        </div>
        <teleport to="body">
            <div class="modalcustom" v-if="isOpen">
                <div>
                    <h3 class="is-size-5"><strong>Payment slip submission</strong></h3>
                    <input type="text" class="input pb-2" label="Client Name" placeholder="Client Name" v-model="client_name">
                    <p>Ensure slip amount is atleast UGX {{(0.3 * quotation.amount_due).toLocaleString() }}, that is, 30% of UGX {{ (1 * quotation.amount_due).toLocaleString() }}</p>
                    <p><input type="file" class="input pb-2" accept="image/*" name="filename" @change="onFileSelected"></p>
                    <p class="pb-3" v-if="this.selectedFile == ''"><button class="button is-small is-success" @click="selectSlip">Upload file</button></p>
                    <p class="pb-3" v-else><button class="button is-small is-success" @click="submitSlip">Upload file</button></p>
                    <div class="is-pulled-right">
                        <button class="button is-success" @click="isOpen = false">Close</button>
                    </div>     
                </div>
            </div>
        </teleport>

        <hr>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
export default {
    name: 'Quotation',
    props: {
        quotation: Object
    },
    data(){
        return{
            isOpen : false,
            client_name: this.$store.state.name,
            selectedFile: '',
            errors: [],
        }
    },
    methods: {
        getItemTotal(item) {
            return item.quantity * item.product.price
        },
        quotationTotalLength(quotation) {
            return quotation.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        onFileSelected(event){
            this.selectedFile = event.target.files[0]
        },
        selectSlip(){
            toast({
                    message: 'Please select slip to upload',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'bottom-right',
        })},
        submitSlip(){
            const data = {
                "quotation_id": this.quotation.id,
                "payment_slip": this.selectedFile
            }
            //console.log(data)
            axios
                .post('/api/v1/pay-slip-upload/', data, {
                    headers: {
                    "Content-Type": "multipart/form-data",
                },
                } 
                )
                .then(response => {
                    toast({
                    message: 'The payment slip was successfully submitted',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'bottom-right',
                })
                this.isOpen = false
                })
                .catch(error => {
                this.errors.push('Something went wrong, Please try again')
                console.log(error) 
                })
        },
    }
}
</script>

<style scoped>
td{
    padding-left: 2px;
    padding-right: 2px;
}
.box{
    margin-right: 2px;
    margin-left:2px;
}

.rootcustom {
    position: relative;
}
.modalcustom {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalcustom > div {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
}
</style>