<template>
    <a class="button is-success" v-if="this.$store.state.name != ''"
    style="padding:5px"
    @mousedown="downloadFile">
        Download File
    </a>
    <router-link to="/register" style="padding:5px" v-else>
        <a class="button is-success" style="padding:5px">
            Download File
        </a>
    </router-link>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
    props: ['dom', 'name'],
    methods: {
        downloadFile(){
            const me = this;
            const invoice = document.querySelector(me.dom);
            var opt = {
                margin: 5,
                filename:me.name,
            };
            html2pdf().from(invoice).set(opt).save();
        },     
    },
};
</script>

<style scoped>
.is-success{
  background-color:#004100;
}
</style>