<template>
<div>
    <TopName name="Materials Checkout" />
    <div class=" section pt-2 page-checkout">
        <div class="columns is-multiline">
            <div class="column is-12 box">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Material</th>
                            <th>Unit Price</th>
                            <th>Qty</th>
                            <th>Total</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="item in matcart.items"
                            v-bind:key="item.material.id"
							>
                            <td>{{ item.material.name }}</td>
                            <td>{{ item.material.price }}</td>
                            <td>{{ item.quantity }}</td>
                            <td>{{ getItemTotal(item).toFixed(0) }}</td>
                            
                        </tr>
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colspan="2">Total</td>
                            <td>{{ matcartTotalLength }}</td>
                            <td>UGX {{ matcartTotalPrice.toFixed(0) }}</td>
                            
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div class="column is-12 box has-text-left">
                <h2 class="subtitle">Delivery details</h2>

                <p class="has-text-grey mb-4">* All fields are required</p>

                <div class="columns is-multiline">
                    <div class="column is-6">
                        <div class="field">
                            <label>First name*</label>
                            <div class="control">
								<input type="text" class="input" v-model="first_name" />
                            </div>
                        </div>

                        <div class="field">
                            <label>Last name*</label>
                            <div class="control">
								<input type="text" class="input" v-model="last_name" />
                            </div>
                        </div>

                        <div class="field">
                            <label>E-mail*</label>
                            <div class="control">
								<input type="email" class="input" v-model="email" />
                            </div>
                        </div>

                        
                        <div class="field">
                            <label>Phone*</label>
                            <div class="control">
                                <div class="columns is-mobile">
                                    <div class="column is-5" style="width:auto">
                                        <div class="select">
                                            <select v-model="selectedCountryCode">
                                                <!-- Add your country codes here -->
                                                <option>+256</option>
                                                <!-- Add more options as needed -->
                                            </select>
                                        </div>
                                    </div>
                                    <div class="column is-7">
                                        <input type="tel" placeholder="Number " class="is-fullwidth input" v-model="phone" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="column is-6">
                        <div class="field">
                            <label>Address*</label>
                                <div class="control">
                                    <input type="text" class="input" v-model="address" />
                                </div>
                            <!--
                                <vue-google-autocomplete
                                    ref="address"
                                    id="map"
                                    class="input"
                                    placeholder="Enter delivery location"
                                    v-on:placechanged="getAddressData"
                                    types=""
                                    country="ug"
                                    >
                                </vue-google-autocomplete>
                            -->
                        </div>
                    </div>
                </div>

                <div class="notification is-danger mt-4" v-if="errors.length">
                    <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
                </div>

                <!--<button class="button is-dark" @click="makePayment">Make Payment</button>-->
                <div style="margin-bottom: 10px;">
                    <button class="button has-text-white" style="background-color: #004100" @click="submitOrder">Place Order</button>
                </div>

  
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
 th, td {
     padding: 5px;
 }
</style>
<script>
import axios from 'axios'
import TopName from '@/components/TopName.vue'
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
    name: 'Checkout',
    data(){
        return {
            matcart: {
                items: []
            },
            first_name: '',
            last_name: '',
            email: this.$store.state.username,
            phone: '',
            address: '',
            errors: [],

            selectedCountryCode: '+256',
            countryCodes: {
                '+1': 'United States',
                '+44': 'United Kingdom',
                // Add more countries as needed
            },

        }
    },
    components:{
        TopName,
        VueGoogleAutocomplete
    },
    mounted() {
        document.title = 'Checkout | Tofali Africa'

        this.matcart = this.$store.state.matcart
        
    },
    methods: {
        getAddressData: function (addressData, placeResultData, id) {
            this.address = placeResultData.name;

        },    

        getItemTotal(item) {
            return item.quantity * item.material.price
        },
        generateReference(){
            let date = new Date()
            return date.getTime().toString();
            },
        checkErrors(){
            this.errors = []

            if (this.first_name === '') {
                this.errors.push('The first name field is missing!')
            }

            if (this.last_name === '') {
                this.errors.push('The last name field is missing!')
            }

            if (this.email === '') {
                this.errors.push('The email field is missing!')
            }

            if (this.phone === '') {
                this.errors.push('The phone field is missing!')
            }

            if (this.address === '') {
                this.errors.push('The address field is missing!')
            }
        },
        makePayment() {
            this.checkErrors()

            FlutterwaveCheckout({
                public_key: "FLWPUBK-d9771574c0d993a1c6ad10d261e839a2-X",
                tx_ref: this.generateReference(),
                amount: this.matcartTotalPrice.toFixed(0),
                currency: "UGX",
                payment_options: "card, mobilemoneyug, ussd",
                redirect_url: "http://localhost:8080/matcart/success",
                customer: {
                email: this.email,
                phone_number: this.phone,
                name: this.first_name,
                },
                customizations: {
                title: "Tofali Africa Materials",
                description: "Payment for your materials",
                },
            });    

        },
        formatPhoneNumber(number) {
            // Remove leading zero and add selected country code
            const cleanedNumber = number.replace(/^0+/, '');
            return this.selectedCountryCode + cleanedNumber;
        },
     async submitOrder(){
            const items = []

            this.checkErrors()


            for (let i = 0; i< this.matcart.items.length; i++){
                const item = this.matcart.items[i]
                const obj = {
                    material: item.material.id,
                    quantity: item.quantity,
                    price: item.material.price * item.quantity
                }

                items.push(obj)
                
            }
                console.log(items)

            // Validate and format phone number
            const formattedPhoneNumber = this.formatPhoneNumber(this.phone);

            if (formattedPhoneNumber) {
                console.log('Formatted Phone Number:', formattedPhoneNumber);
            } 


            const data = {
                'first_name': this.first_name,
                'last_name': this.last_name,
                'email': this.email,
                'address': this.address,
                'phone':formattedPhoneNumber,
                'items': items,
            } 


            console.log(data)
    

         await axios
                .post('/api/v1/checkout/', data)
                .then(response => {
                
                    console.log(data)
                    //this.$store.commit('clearmatcart')
                    this.$router.push('/orders')
                })
                .catch(error => {
                    this.errors.push('Please try again')
                    console.log(error) 
                })

                this.$store.commit('setIsLoading', false)
        },
        

    },
    computed: {
        matcartTotalPrice() {
            return this.matcart.items.reduce((acc, curVal) => {
                return acc += curVal.material.price * curVal.quantity
            }, 0)
        },
        matcartTotalLength() {
            return this.matcart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        }
    }
}
</script>