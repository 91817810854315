<template>
    <div class="ml-0">
        <h3 class="is-size-4 has-text-left has-text-success">Materials Order #{{ order.id }}</h3>
        <h4 class="is-size-5 has-text-left">Status: {{ order.status }}</h4>
        <h4 class="is-size-5 has-text-left">Total amount: UGX  {{ (1 * order.amount_due).toLocaleString() }}</h4>
        <h4 class="is-size-5 has-text-left">Materials</h4>

        <table class="table">
            <thead>
                <tr>
                    <th>Material</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Total</th>
                </tr>
            </thead>

            <tbody class="">
                <tr
                    v-for="item in order.items"
                    v-bind:key="item.material.id"
                >
                    <td class="has-text-left">{{ item.material.name }}</td>
                    <td class="has-text-right">{{ item.material.price }}</td>
                    <td class="has-text-center">{{ item.quantity }}</td>
                    <td class="has-text-right">{{ getItemTotal(item).toLocaleString() }}</td>
                </tr>
            </tbody>
        </table>
        
        <div class="section pt-1">
            <div class="rootcustom-pay is-pulled-right" v-if="order.amount_due <= 5000000">
                <!--<p class="pb-3"><button class="button is-success" @click="isOpenPay = true">Pay for Order</button></p>-->
                <button class="button is-success" v-if="order.amount_due <= 5000000" @click="makePayment()">Pay for Order</button>
                <button class="button is-success" v-else="order.amount_due > 5000000" @click=checkAmount()>Pay for Order</button>
            </div>
            <div class="rootcustom-order is-pulled-left">
                <button class="is-success button" @click="isOpen = true">Submit Slip</button>
            </div>
        </div>
        
        <teleport to="body">
            <div class="modalcustom-order" v-if="isOpenPay">
                <div>
                    <div class="control">
                        <p>Amount due: {{ order.amount_due }}</p>
                        <input class="input mb-3" type="number" placeholder="Amount to Pay" min="0"  name="number" v-model="amount_to_pay">
                        <button class="button is-success" v-if="this.amount_to_pay <= 5000000 && this.amount_to_pay == order.amount_due" @click="makePayment()">Pay amount</button>
                        <button class="button is-success" v-else="this.amount_to_pay > 5000000" @click=checkAmount()>Pay amount</button>
                        <button class="button is-success" v-else="this.amount_to_pay < order.amount_due " @click=checkAmountBelow()>Pay amount</button>
                    </div>
                    <div class="is-pulled-right">
                        <button class="button is-success" @click="isOpenPay = false">Close</button>
                    </div>
                </div>
            </div>
        </teleport>
        <teleport to="body">
            <div class="modalcustom-order" v-if="isOpen">
                <div>
                    <div>
                        Please pay through bank, and submit payment slip
                    </div>
                    
                    <div class="pt-2">
                        <h3 class="is-size-5"><strong>Receipt submission</strong></h3>
                        <p><strong>For amounts above UGX 5 million </strong></p>
                    <p>Ensure slip amount is equal to UGX {{(order.amount_due).toLocaleString() }}</p>
                    <p><input type="file" class="input pb-2" accept="image/*" name="filename" @change="onFileSelected"></p>
                    <p class="pb-3" v-if="this.selectedFile == ''"><button class="button is-small is-success" @click="selectSlip">Upload file</button></p>
                    <p class="pb-3" v-else><button class="button is-small is-success" @click="submitSlip">Upload file</button></p> 
                    </div>
                    <div class="is-pulled-right">
                        <button class="button is-success" @click="isOpen = false">Close</button>
                    </div>
                </div>
            </div>
        </teleport>
        <hr>
    </div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
export default {
    name: 'OrderSummary',
    data(){
        return{
            isOpen : false,
            isOpenPay : false,
            selectedFile: '',
            amount_to_pay: '',
        }
    },
    props: {
        order: Object
    },
    methods: {
        getItemTotal(item) {
            return item.quantity * item.material.price
        },
        orderTotalLength(order) {
            return order.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        selectSlip(){
            toast({
                    message: 'Please select slip to upload',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'bottom-right',
        })},
        submitSlip(){
            const data = {
                "quotation_id": this.order.id,
                "payment_slip": this.selectedFile
            }
            //console.log(data)
            axios
                .post('/api/v1/pay-slip-upload/', data, {
                    headers: {
                    "Content-Type": "multipart/form-data",
                },
                } 
                )
                .then(response => {
                    toast({
                    message: 'The payment slip was successfully submitted',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 3000,
                    position: 'bottom-right',
                })
                this.isOpen = false
                })
                .catch(error => {
                this.errors.push('Something went wrong, Please try again')
                console.log(error) 
                })
        },
        //Flutterwave payments
        generateReference(){
            let date = new Date()
            return date.getTime().toString();
        },
        makePayment(){
            if (this.order.amount_due == ''){
            toast({
                message: 'Enter amount to make payment',
                    type: 'is-warning',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 6000,
                    position: 'bottom-right',
            })
        }else{
            FlutterwaveCheckout({
                public_key: "FLWPUBK-ab055665b135b92cc6835811d8797f42-X",
                tx_ref: this.generateReference(),
                amount: this.order.amount_due,
                currency: "UGX",
                payment_options: "card, mobilemoneyug, ussd",
                redirect_url: '',
                customer: {
                email: this.$store.state.email,
                phone_number: this.$store.state.phone_contact,
                name: this.$store.state.name,
                },
                customizations: {
                title: "Tofali Africa",
                description: "Payment for your quotation",
                },
                onclose: this.closedPaymentModal
            });
        }
        },
        checkAmountBelow(){
            toast({
                message: 'Amount to pay online must be equal to amount due.  Else use our account numbers to complete the transaction.',
                    type: 'is-warning',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 6000,
                    position: 'bottom-right',
            })
        },
        checkAmount(){
            toast({
                message: 'Amount to pay online must be below five million ugandan shillings. Else use our account numbers to complete the transaction.',
                    type: 'is-warning',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 6000,
                    position: 'bottom-right',
            })
        },
        
    }
}
</script>

<style scoped>
td{
    padding-left: 2px;
    padding-right: 2px;
}
.box{
    margin-right: 2px;
    margin-left:2px;
}
.rootcustom-pay {
    position: relative;
}
.rootcustom-order {
    position: relative;
}
.modalcustom-order {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalcustom-order > div {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
}
</style>