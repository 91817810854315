<template>
<TopName name="Success" />
<div>
    <div class="section">
        <h2>
            <strong>Thank you! We shall contact you shortly</strong>
        </h2>
        <div class="box">
            <p>Order has been made successfully!</p>
        </div>
        <router-link to="/">
            <button class="button is-success">Home</button>
        </router-link>
    </div>
</div>  
</template>
<script>
import TopName from '@/components/TopName.vue';

export default{
    name: 'Success',
    components: {
    TopName
}
}
</script>

<style>

</style>