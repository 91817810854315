<template>
<div>
    <TopName name="Materials Cart" />
    <div class=" section page-cart">
        <div class="columns is-multiline">
            

            <div class="column is-12 box">
                <table class="table is-fullwidth" v-if="matcartTotalLength">
                    <thead>
                        <tr>
                            <th>Material</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        <MatCartItem
                            v-for="item in matcart.items"
                            v-bind:key="item.material.id"
                            v-bind:initialItem="item"
                            v-on:removeFromCart="removeFromCart" />
                    </tbody>
                </table>

                <p v-else>You don't have any materials in your cart...</p>
            </div>

            <div class="column is-12 box">
                <h2 class="subtitle">Summary</h2>

                <strong>UGX{{ matcartTotalPrice }}</strong>

                <hr>

                <router-link to="/matcart/checkout" class="button has-text-white" style="background-color: #004100">Proceed to checkout</router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import MatCartItem from '@/components/MatCartItem.vue'
import TopName from '@/components/TopName.vue'

export default {
    name: 'MatCart',
    components: {
        MatCartItem,
        TopName
    },
    data() {
        return {
            matcart: {
                items: []
            }
        }
    },
    
    mounted() {
        this.matcart = this.$store.state.matcart
    },
    methods: {
        removeFromCart(item) {
            this.matcart.items = this.matcart.items.filter(i => i.material.id !== item.material.id)
        }
    },
    computed: {
        matcartTotalLength() {
            return this.matcart.items.reduce((acc, curVal) => {
                return acc += curVal.quantity
            }, 0)
        },
        matcartTotalPrice() {
            return this.matcart.items.reduce((acc, curVal) => {
                return acc += curVal.material.price * curVal.quantity
            }, 0).toLocaleString()
        },
    }
}
</script>