<template v-if="location">
    <option v-bind:value="JSON.stringify({
        id:location.id,
        name:location.name
    })">
        {{location.name}}
    </option>
</template>

<script>
    export default{
        name:'Location',
        props:{
            location: Object
        },
    }
</script>

<style>
</style>