<template>
<div>
    <router-view >
      
    </router-view> 
<div>
  <div class="navigation" style="margin-top: 50px;">
    <div class="nav-box">
      <router-link to="/">
        <p>
          <span><i class="icofont-ui-home"></i></span>
          <span>Home</span>
        </p>
      </router-link>
    </div>
    
    <div class="nav-box">
      <router-link to="/results">
        <p>
          <span><i class="icofont-calculations"></i></span>
          <span>Results</span>
        </p>
      </router-link>
    </div>
    <div class="nav-box">
      <router-link to="/projects">
        <p>
          <span><i class="icofont-calendar"></i></span>
          <span>Projects</span>
        </p>
      </router-link>
    </div>
    <div class="nav-box">
      <router-link to="/orders">
        <p>
          <span><i class="icofont-ui-note"></i></span>
          <span>Orders</span>
        </p>
      </router-link>
    </div>
    <div class="nav-box">
      <router-link to="/about">
        <p>
          <span><i class="icofont-ui-user"></i></span>
          <span>Account</span>
        </p>
      </router-link>
    </div>
    
  </div>

</div>

</div>

    
  
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import { toast } from 'bulma-toast';
import update from './mixins/update'


export default {
  data() {
    return {
      showMobileMenu: false,
      cart: {
        items: []
      },
    }
  },
  mixins: [update],
  components:{
  
},
  computed:{
    ...mapGetters(["products"]),
    allProducts(){
      return this.products
    },
  },
  beforeCreate(){
    this.$store.commit('initializeStore')

    const access = this.$store.state.access

    if( access ){
      axios.defaults.headers.common['Authorization'] = "JWT " + access
    }else{
      axios.defaults.headers.common['Authorization'] = '' 
    }
  },
  mounted(){
    this.cart = this.$store.state.cart
    //setInterval(()=> {
      //this.getAccess()
    //}, 3000)
    const firebaseConfig = {
      apiKey: "AIzaSyD1bg05c4iH0mdx6iX7itaNJjYuwk3WDEc",
      authDomain: "tofali-16dc2.firebaseapp.com",
      projectId: "tofali-16dc2",
      storageBucket: "tofali-16dc2.appspot.com",
      messagingSenderId: "181656334033",
      appId: "1:181656334033:web:84f5fb1edfc54914711ff0",
      measurementId: "G-STNF74DNHD"
};


const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  toast({
        message: payload.notification.title,
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true,
        duration: 10000,
        position: 'top-center',
    })
});


// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
getToken(messaging, { vapidKey: 'BFI6NcEHPyo2qk5MSpuIFIqVlYAqShzOy8won8tZ506IDa1gP5DLKyT2LSpwT1JqqaRnxAZjiprbFTzvody7sIc' }).then((currentToken) => {
  if (currentToken) {
    console.log('Token is',currentToken)
    this.saveNotificationToken(currentToken)
    // Send the token to your server and update the UI if necessary
    // ...
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
    // ...
  }
}).catch((err) => {
  console.log('An error occurred while retrieving token. ', err);
  // ...
});
  },
  methods: {
    ...mapActions(["fetchAllProducts"]),
    clearCart(){
        this.$store.commit('clearCart')
    },
    saveNotificationToken(token){
      const registerNotifTokenURL = '/devices'
      const payload = {
        registration_id: token,
        type: 'web'
      }
      axios.post(registerNotifTokenURL, payload)
        .then((response) => {
          console.log('Successfully saved notification token!')
          console.log(response.data)
        })
        .catch((error) => {
          console.log('Error: could not save notification token')
          if (error.response) {
            console.log(error.response.status)
            // Most of the time a "this field must be unique" error will be returned,
            // meaning that the token already exists in db, which is good.
            if (error.response.data.registration_id) {
              for (let err of error.response.data.registration_id) {
                console.log(err)
              }
            } else {
              console.log('No reason returned by backend')
            }
            // If the request could not be sent because of a network error for example
          } else if (error.request) {
            console.log('A network error occurred.')
            // For any other kind of error
          } else {
            console.log(error.message)
          }
        })
      },
    getAccess(){
      const accessData = {
        refresh: this.$store.state.refresh
      }
      axios
        .post('/api/v1/jwt/refresh/', accessData)
        .then(response => {
          const access = response.data.access

          console.log(access)

          localStorage.setItem("access", access)
          this.$store.commit('setAccess', access)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
}
</script>


<style lang="scss">
@import '../node_modules/bulma';
@import '~@creativebulma/bulma-collapsible';



#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}



.navbar-brand {
  min-height: 3.25rem;
  display: flex;
}

nav {
  a {
    &.router-link-exact-active {
      color: rgb(0, 65, 0);
    }
  }
}

.is-success{
    background-color:#004100;
}






@media only screen and (min-width: 320px) and (max-width: 567px){
#footer .widgetized-footer {
    padding: 40px 0 15px 0;
}}
@media only screen and (min-width: 769px){
#footer .footer-widget h3.widget-title {
    margin-bottom: 10px;
}
.row{
  display: flex;
}
}
#footer .widgetized-footer {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 60px 0 0 0;
}
#footer .footer-widget h3.widget-title {
    margin: 0 0 20px 0;
    padding: 0 0 15px 0;
    color: #fff;
    position: relative;
}
#footer .footer-widget h3 {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.5em;
}
#footer .footer-widget ul.menu-links li {
    display: block;
    padding: 5px 0;
    color: #aaa;
}
#footer {
    background-image: url();
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;
    padding: 0;
    background-color: #28282e;
    font-weight: 400;
    color: #76767b;
}

img.mb-1 {
    max-width: 200px;
}

img{
  vertical-align: bottom;
  height: auto;
  border: 0;
}
</style>

<style lang='scss' scoped>
.navigation{
  width :100%;
   z-index :100;
   position : fixed;
   //line-height: .4rem;
   bottom: 0;
   height : 4.3rem;
   background-color: #ffffff;
   opacity :0.8rem;
   left: 0;
   border-top : .02rem solid #eee;
   display: flex;
   font-size :.2rem;
   
}  
   .nav-box{
      flex: 1;
      width:  20%;
      text-align: center;
      font-size: 1rem;
      color: #ffffff;
      display:  flex;
      flex-direction :column;
      justify-content: center;
      justify-items: center;
      padding-bottom: 20px;
   }
      p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        color: #004100;
      }
        span{
          flex: 1;
        }


</style>
<style lang="css" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.moveUp-enter-active {
  animation: fadeIn 250ms ease-in;
}

@keyframes fadeIn {
  0% { opacity: 0;}
  50% { opacity: 0.5;}
  100% { opacity: 1;}
}

</style>

