<template>
<div>
<TopName name="Orders" />
    <div class="section pt-2 pl-0">
        <template v-if="quotations != ''">
            <div class="column is-12">
                <Quotation
                    v-for="quotation in quotations"
                    v-bind:key="quotation.id"
                    v-bind:quotation="quotation"
                />
            </div>
        </template>
        <template v-if="orders != ''">
            <div class="column is-12">
                <OrderSummary
                    v-for="order in orders"
                    v-bind:key="order.id"
                    v-bind:order="order" />
            </div>
        </template>
        <template v-else>

            <ProjectLoading description="Orders will be shown here" />
        </template>
    </div>
</div>  
</template>

<script>
import axios from 'axios'
import TopName from '@/components/TopName.vue';
import ProjectLoading from '@/components/ProjectLoading.vue';
import OrderSummary from '@/components/OrderSummary.vue';
import Quotation from '@/components/Quotation.vue';

export default{
    name: 'Orders',
    data(){
        return {
            orders: [],
            quotations: [],
        }
    },
    mounted(){
        document.title = "My Orders | Tofali Africa"
        this.getMyOrders()
        this.getMyQuotations()
    },
    components:{
        TopName,
        ProjectLoading,
        OrderSummary,
        Quotation
    },
    methods:{
        async getMyQuotations(){
            await axios
                .get('/api/v1/quotations/')
                .then(response => {
                    //console.log(response.data)
                    this.quotations = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getMyOrders() {
            //this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/orders/')
                .then(response => {
                    this.orders = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        }
    }    
}
</script>

<style scoped>
.section{
    overflow-x: hidden;
}
</style>