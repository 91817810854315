<template>
    <div>
        <div class="portfolio has-text-white has-text-centered" >
            <p class="pt-3"><span style="font-size:36px">PORTFOLIO</span></p>
            <p><span style="font-size:22px">Awesome work and Happy Clients</span></p>        
        </div>

        <div class="row" id="grid-portfolio" >
            <div class="column is-4" style="position: relative;left:0%; top:0px;">
            <p>The gallery goes here</p>
            </div>
        </div>



    </div>
</template>

<script>

</script>

<style scoped>
@media  (min-width: 769px ) {
    .row {
        display: flex;
    }    
}
.portfolio{
    height: 200px;
    background-color: rgba(0,0,0,0.5);
    background-image: url('https://res.cloudinary.com/celestialmk/image/upload/v1660030549/ihx3r3g5wr77e3tfoxul.jpg');
}
#grid-portfolio .gallery-item {
    margin-bottom: 10px;
}

.gallery-item .gallery-desc {
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    overflow: hidden;
    transition: .3s ease;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
}
</style>