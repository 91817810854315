<template>
<div>
  <TopName name="Materials Calculation" />
  <div class="home pb-2">
        <div class="section">
          <div class="has-text-left">
            <h3><strong>SITE LOCATION</strong></h3>
          </div>   
        <vue-google-autocomplete
          ref="address"
          id="map"
          class="input"
          placeholder="Enter site location"
          v-on:placechanged="getAddressData"
          types=""
          country="ug"
        >
      </vue-google-autocomplete>
      <p><strong>Site location affects total cost of production</strong></p>
      </div>

      <div class="section has-text-left">
          <div class="dropdown" id="drop">
            <div class="dropdown-trigger">
              <button class="button" aria-haspopup="true" aria-controls="dropdown-menu" onclick="this.parentNode.parentNode.classList.toggle('is-active')">   
                <span class="pr-3"><strong>Strength Class :  </strong></span>
                  <span v-if="this.selectedValue ==''">Select Strength Class:</span>
                  <span v-else>{{ selectedValue }}</span>
                  <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
              </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
              <div class="dropdown-content">
                  <a class="dropdown-item is-size-6" v-for="option in options" :key="option.id" @click="selectOption(option)">
                  {{ option.label }}
                  </a>
              </div>
              </div>
          </div>
        </div>

        <div class="section">
          <h5 class="has-text-left has-text-weight-bold mt-2">PRODUCTS</h5>
          <div class="columns is-mobile has-text-left has-text-weight-bold">
            <div class="column is-4">
              <h6> <strong>Size</strong></h6>
            </div>
            <div class="column is-3 has-text-centered">
              <h6><strong>Type</strong></h6>
            </div>
            <div class="column is-">
              <h6><strong>Quantity</strong></h6>
            </div>
            
          </div>
          <hr class="mt-0">
          <ul id="prods" :key="updateProducts">
            <template v-if="this.$store.state.products != ''">
              <ProductBox 
                  v-for="product in this.$store.state.products"
                  v-bind:key="product.id"
                  v-bind:product="product"
                />
            </template>
            <template v-else>
              <ProductLoading />
            </template>
            </ul>
        </div>      

    <div class="section">
      <div class="box has-text-left">
        <div class="columns is-mobile">
          <div class="column is-6 has-text-left">
            Total
          </div>
          <div class="column is-6 has-text-right">
            {{this.$store.state.total_quantity}}
          </div>
        </div>
      </div>
    </div>

    <!--&& this.$store.state.total_quantity >= 5000-->
    <div class="section" v-if="this.$store.state.location_name != ''" style="margin-bottom: 70px;">
      <div v-if="this.$store.state.total_quantity >= 5000" >
        <router-link to="/results" @click.native="scrollToTop" @click="createInvoice">
          <a class="button is-success pb-2">
            Calculate Total
          </a>
        </router-link>
      </div>
      <div class="section" v-else style="margin-bottom: 70px;">
        <a class="button is-success pb-2" style="background-color:#004100" @click="checkTotal">
          Calculate Total
        </a>
      </div> 
    </div>
    <div class="section" v-else style="margin-bottom: 70px;">
        <a class="button is-success pb-2" style="background-color:#004100" @click="checkLocation">
          Calculate Total
        </a>
    </div>    

  <!--

    <div class="section">
      
      <div class="field has-addons"> 
        <div class="control">
          <input type="number" class="input" min="1"
      v-model = "quantity">
    </div>
    <div class="control">
      <a class="button is-success" @click="addToCart()">
        Calculate
      </a>
    </div>
  </div>
</div>
  -->
</div>

</div>
</template>

<script>
import swal from 'sweetalert';
import ProductBox from '../components/ProductBox.vue'
import ProductLoading from '../components/ProductLoading.vue'
import Location from '../components/Location.vue'
import Cart from './Cart.vue'
import VueGoogleAutocomplete from "vue-google-autocomplete";
import store from '@/store/index.js';
//import { ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TopName from '../components/TopName.vue';
import axios from 'axios';

export default {
  name: 'HomeView',
  data(){
    return {
      product: {},
      location:{},
      selected:'',
      quantity: 4000,
      address:'',
      products: this.$store.state.products,

      invoiceNumber: '',
      cart:{
          items:[]
      },
      isOpen: false,
      selectedValue: '3 MPa (Default)',
      updateProducts:0, //increment to force update 
      options:[
              { id: 1, label: '3 MPa'},
              { id: 2, label: '5 MPa'},
      ]
    }
  },
  mounted(){
    this.cart = this.$store.state.cart
  },
  computed: {
    
    //products() {
      //return this.$store.state.products;
    //},
    //...mapGetters(["products"]),
    /*
    products(){
      if (this.$store.state.products == ''){
       this.$store.commit('setIsLoading', true)
      }else if ( this.selectedValue = '3 MPa'){
       this.$store.commit('setIsLoading', false)
        return this.$store.getters.products
      } else if ( this.selectedValue = '5 MPa'){
        return this.$store.getters.products2
      }
    },*/
    cement_price(){
        return this.$store.getters.cement_price
    },
    stone_dust_price(){
        return this.$store.getters.stone_dust_price
    },
    cartTotalLength(){ // return total amount of blocks selected
        return this.cart.items.reduce((acc, curVal) => {
            return  acc += curVal.quantity 
        },
      1)
    },
  },
  components: {
    Location,
    ProductBox,
    ProductLoading,
    Cart,
    VueGoogleAutocomplete,
    ProductLoading,
    TopName
},
  mounted(){
    this.$refs.address.focus();
  },
  methods:{
    toggleDropdown() {
        this.isOpen = !this.isOpen
    },
  
    selectOption(option) {
        this.selectedValue = option.label
        if(option.label == '3 MPa'){
          const products = this.$store.state.products
          store.commit('setProducts', products)
          //console.log(this.products)
        } else if (option.label == '5 MPa'){
          const products2 = this.$store.state.products2
          store.commit('setProducts', products2)
          //console.log(this.products)
        } else if (option.label == ''){
          this.products = this.$store.getters.products
        }
        
        const element = document.getElementById('drop')
        element.classList.remove('is-active')
    },



     getAddressData: function (addressData, placeResultData, id) {
        this.address = addressData;
        this.placeResultData = placeResultData

        const origin = new google.maps.LatLng(0.31743, 32.59437)
        const destination = new google.maps.LatLng(this.address.latitude, this.address.longitude)

        console.log(this.address.administrative_area_level_1)
        console.log(this.address.locality)
        const location_name = this.placeResultData.name
        store.commit('setLocationName', location_name)

        const cement = this.$store.state.cement

        for ( let i = 0; i < cement.length; i++){
          if(cement[i].location == this.address.locality){
            console.log(cement[i].location)
            store.commit('setCementPrice', 0)
            store.commit('setCementCem2Price', 0)
            store.commit('setCementPrice', cement[i].CEM_IV_Price)
            store.commit('setCementCem2Price', cement[i].CEM_II_Price)
            break
          }else{
            console.log(cement[i].location)
            store.commit('setCementPrice', 33000)
            store.commit('setCementCem2Price', 36000)
          }
        }

        console.log(this.$store.state.cement_cem_II_price)
        console.log(this.$store.state.cement_cem_IV_price)

        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            travelMode:'DRIVING',
            unitSystem: google.maps.UnitSystem.metric,
            avoidHighways: false,
            avoidTolls: false
        }, callback);

        function callback(response, status){
          if (status != google.maps.DistanceMatrixStatus.OK){
            $('#result').html(err);
          }else {
            var origin = response.originAddresses[0];
            console.log(origin);
            var destination = response.destinationAddresses[0];
            console.log(destination);
            if (response.rows[0].elements[0].status === 'ZERO_RESULTS'){
              $('#result').html(
                'Please enter a site location or a location near the site'
              );
            }else {
              var distance = response.rows[0].elements[0].distance;
              console.log(distance);
              var distance_in_kilo = distance.value / 1000;
              console.log(distance_in_kilo)

              //get location info and set it to state
              
              
              const location_distance = distance_in_kilo
              store.commit('setLocationDistance', location_distance)

              //const mobilisation_cost = distance_in_kilo * 10000
              //store.commit('setMobilisationCost', mobilisation_cost)
              
              if(distance_in_kilo < 60 ){
                const mobilisation_cost = 700000
                console.log(mobilisation_cost)
                //set mobilisation cost for region
                store.commit('setMobilisationCost', mobilisation_cost)
                
              }else {
                const mobilisation_cost = 700000 + ((distance_in_kilo - 60)* 2*5000);
                console.log(mobilisation_cost)
                store.commit('setMobilisationCost', mobilisation_cost)
              }
            }
            
          }
          }
      },
      ...mapActions(["fetchAllProducts"]),
      ...mapActions(["fetchAllProducts2"]),
      ...mapActions(["fetchCement"]),
      ...mapActions(['fetchStoneDust']),
      checkLocation(){
        swal({
            title:"Enter a site location",
            icon:"warning",
            button:"OK"
        })
      },
      checkTotal(){
        swal({
              title: "Sorry!!",
              text:"Total quantity should be above 5000 or contact us at admin@ecoconcrete.co.ug, 039 3240090 , for units below 5000",
              icon:"warning",
              button: "OK"
        }),
         this.$store.commit('clearTotal') 
         this.updateProducts += 1 //hot reload products list
         
      },

      scrollToTop(){
      window.scrollTo(0,0)
      },

      createInvoice(){
      axios.get('/api/v1/get-invoice-number/')
            .then(response => {
            this.invoiceNumber = response.data.number
            this.$store.commit('setInvoiceNumber', this.invoiceNumber)
      })
    }

  },
      created(){
        this.fetchAllProducts();
        this.fetchAllProducts2();
        this.fetchCement();
        this.fetchStoneDust();
        this.fetchStoneDust();
      },


    onChange: function(e){
      var id = e.target.key;
      var name = e.target.options[e.target.options.selectedIndex].text;
      console.log('id', id);
      console.log('name', name);
    },

    addToCart(){
      if (isNaN(this.quantity) || this.quantity < 1){
        this.quantity = 1
      }

      var e = document.getElementById('blocks');

      this.product = e.options[e.selectedIndex].value;

      const item = {
        product: JSON.parse(this.product), //covert stringified product to JSON
        quantity: this.quantity
      }

      if (this.quantity > 5000){
        this.$store.commit('addToCart', item)
      }

      console.log(item)
    },
  }

</script>

<style scoped>
.section{
  padding-top: 5px;
  padding-bottom: 5px;
}
.is-success{
  background-color:#004100;
}
</style>
