import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import Cart from '../views/Cart.vue'
import PostInfo from '../views/PostInfo.vue'
import Register from '../views/Register.vue'
import IndexView from '../views/IndexView.vue'
import PortFolio from '../views/PortFolio.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import DocumentView from '../views/DocumentView.vue'
import Success from '../views/Success.vue'
import Projects from '../views/Projects.vue'
import Orders from '../views/Orders.vue'
import Material from '../views/Material.vue'
import MatCheckout from '../views/MatCheckout.vue'
import MatCart from '../views/MatCart.vue'
import ResetEmail from '../views/ResetEmail.vue'
import ResetConfirm from '../views/ResetConfirm.vue'



const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
    //meta: { requireLogin: true}
  },
  {
    path: '/materials-calculator',
    name: 'home',
    component: HomeView,
    //meta: {requireLogin: true}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: { requireLogin: true}
  },
  {
    path: '/results',
    name: 'Cart',
    component: Cart,
    //meta: {requireLogin:true}
  },
  {
    path: '/postinfo',
    name: 'PostInfo',
    component: PostInfo,
    meta: {requireLogin:true}
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {requireLogin: true}
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('../views/PortFolio.vue'),
    meta: { requireLogin: true}
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn,
  },
  {
    path: '/reset-email',
    name: 'ResetEmail',
    component: ResetEmail,
  },
  {
    path: '/password/reset/confirm/:uid/:token',
    name: 'ResetConfirm',
    component: ResetConfirm,
  },
  {
    path: '/documents',
    name: 'DocumentView',
    component: () => import('../views/DocumentView.vue'),
    meta: {requireLogin:true}
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
    meta: { requireLogin: true}
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Projects.vue'),
    meta: { requireLogin: true}
  },
  {
    path: '/materials',
    name: 'Materials',
    component: () => import(/* webpackChunkName: "materials" */'../views/Materials.vue'),
    meta: { requireLogin: true}
  },
  {
    path: '/materials/:material_slug/',
    name: 'Material',
    component: () => import('../views/Material.vue'),
    meta: { requireLogin: true}
  },
  {
    path: '/matcart',
    name: 'MatCart',
    component: () => import('../views/MatCart.vue'),
  },
  {
    path: '/matcart/checkout',
    name: 'MatCheckout',
    component: () => import('../views/MatCheckout.vue'),
    meta: { requireLogin: true}
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/Orders.vue'),
    meta: { requireLogin: true}
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import('../views/Confirmation.vue'),
    //meta: { requireLogin: true}
  },
  {
    path: '/confirmation-self',
    name: 'ConfirmationSelf',
    component: () => import('../views/ConfirmationSelf.vue'),
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/payment-self',
    name: 'PaymentSelf',
    component: () => import('../views/PaymentSelf.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue')
  },
  {
    path:'/privacy-policy',
    name: 'Policy',
    component: () => import('../views/Policy.vue'),
  },
  {
    path:'/quotation-downloads',
    name: 'QuotationDownload',
    component: () => import('../views/QuotationDownload.vue'),
    meta: { requireLogin : true}
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
      next({ name: 'LogIn', query: { to: to.path } });
  } else {
      next()
    }
})

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireLogin)) {
    if (localStorage.getItem('access') != '') {
      next()
      return
    }
    next('/log-in')
  }
  next()
})
*/

export default router
