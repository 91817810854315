<template>
<nav class="navbar is-light is-fixed-top">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <!--<img src="https://res.cloudinary.com/celestialmk/image/upload/v1656186253/gxba9p09dsp6qs68sipl.png" alt="">
          
        <strong>TOFALI MATERIALS ESTIMATOR</strong>-->
        <img src="https://res.cloudinary.com/celestialmk/image/upload/v1656789200/owpuw98v7us7vnd6fvok.png" alt="">
        </router-link>
      </div>
    </nav>

</template>

<script>
export default{
  name: 'TopBar',
}
</script>

<style scoped>

.navigation {
  width :100%;
   z-index :100;
   position :fixed;
   line-height: .4rem;
   top:  0;
   height : 3.25rem;
   background-color: #ffffff;
   opacity :0.8rem;
   left: 0;
   border-bottom : .02rem solid #eee;
   display: flex;
   font-size :.2rem;
}

.navbar-brand {
  min-height: 3.25rem;
  display: flex;
}

</style>