<template>
<div class="pt-1 ml-3 has-text-left">   
    <a @click="goBack" class="button is-success">
    <i class="fas fa-arrow-left">  back</i>
    </a>
</div>
<div class="section has-text-left">
        <h3 class="title">Documents Available</h3>
        <ol class="box">
            <Document
                v-for="document in documents"
                v-bind:key="document.id"
                v-bind:document="document"
            />
        </ol>
    </div>
</template>

<script>
import axios from 'axios'

import Document from '@/components/Document.vue'

export default {
    name: 'DocumentView',
    data(){
        return {
            documents:[],
        }
    },
    components:{
    Document,
},
    mounted(){
        this.getDocuments()
        document.title = 'Tofali Africa | Documents'
    },
    methods:{
        getDocuments(){
            axios 
                .get('/api/v1/documents/')
                .then(response => {
                    this.documents = response.data
                    console.log('docs obtained')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        goBack(){
          this.$router.go(-1)
        },

    }
}
</script>

<style>

</style>