import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
  state: {
    cart :{
      items:[]
    },
    matcart:{
      items:[]
    },
    planter:[],
    quotation_items:[],
    quotation_items_self:[],
    username:'',
    isAuthenticated: false,
    //token: '',
    access: '',
    refresh: '',
    name:'',
    email:'',
    phone_contact:'',
    company_name:'',
    mobilisation_cost:'',
    location_name:'',
    location_distance:'',

    total_quantity: 0,
    cement_quantity: 0,
    cement_II_quantity: 0,
    stone_quantity:0,
    cement_total_cost:0,
    stone_total_cost:0,
    sand_total_cost:0,
    cement_cem1_total:0,
    cement_cem2_total:0,
    cement_II_total:0,
    cement_II_total_cost:0,
    stone_dust_total:0,
    isLoading: false,

    cement_cem_II_price:36000,
    cement_cem_IV_price:33000,

    products:[],
    products2:[],
    materials:[],
    cement:[],
    stone_dust:[],
    cement_price:[],
    stone_dust_price: 50000,
    cement_II_price: [],
    sand_price:[],
    projects:[],
    blocks:[],
    blocks_made:[],
    isOnline: true,
    total_amount_company: 0,
    total_amount_self:0,

    invoice_number:'',
  },
  getters: {
    total_amount_company(state){
      return state.total_amount_company
    },
    cement(state){
      return state.cement
    },
    stone_dust(state){
      return state.stone_dust
    },
    products(state){
      return state.products;
    },
    products2(state){
      return state.products2;
    },
    materials(state){
      return state.materials;
    },
    cement_price(state){
      return state.cement_cem_IV_price
    },
    cement_II_price(state){
      return state.cement_II_price
    },
    stone_dust_price(state){
      return state.stone_dust_price
    },
    sand_price(state){
      return state.sand_price
    },
    projects(state){
      return state.projects
    },
    blocks(state){
      return state.blocks
    },
    isOnline(state){
      return state.isOnline
    },
    blocks_made(state){
      return state.blocks_made
    },
    quotation_items(state){
      return state.quotation_items
    },
    quotation_items_self(state){
      return state.quotation_items_self
    }
  },
  mutations: {
    initializeStore(state){
      if (localStorage.getItem('cart')){
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }
      if (localStorage.getItem('matcart')){
        state.matcart = JSON.parse(localStorage.getItem('matcart'))
      } else {
        localStorage.setItem('matcart', JSON.stringify(state.matcart))
      }
      if(localStorage.getItem('refresh')){
        state.refresh = localStorage.getItem('refresh')
      }else{
        state.refresh = ''
      }
      if(localStorage.getItem('access')){
          state.access = localStorage.getItem('access')
          //state.refresh = localStorage.getItem('refresh')
          state.isAuthenticated = true
      }else{
          state.access = ''
          //state.refresh = ''
          state.isAuthenticated = false
      }

      if(localStorage.getItem('username')){
          state.username = localStorage.getItem('username')
          //state.isAuthenticated = true
      }else{
          //localStorage.setItem('username', state.username)
          //state.isAuthenticated = false
      }
      if(localStorage.getItem('name')){
        state.name = localStorage.getItem('name')
      }else{
        localStorage.setItem('name', state.name)
      }
      if(localStorage.getItem('email')){
        state.email = localStorage.getItem('email')
      }else{
        localStorage.setItem('email', state.email)
      }
      if(localStorage.getItem('phone_contact')){
        state.phone_contact = localStorage.getItem('phone_contact')
      }else{
        localStorage.setItem('phone_contact', state.phone_contact)
      }
      if(localStorage.getItem('company_name')){
        state.company_name = localStorage.getItem('company_name')
      }else{
        localStorage.setItem('company_name', state.company_name)
      }
      /*
      if(localStorage.getItem('total_quantity')){
        state.total_quantity = localStorage.getItem('total_quantity')
      }else{
        state.total_quantity=''
      }*/
    },
    setPlanter(state, planter){
      state.planter = planter
    },
    setCementCem1Total(state, cement_cem1_total){
      state.cement_cem1_total = cement_cem1_total
    },
    setCementCem2Total(state, cement_cem2_total){
      state.cement_cem2_total = cement_cem2_total
    },
    setStoneDustTotal(state, stone_dust_total){
      state.stone_dust_total = stone_dust_total
    },
    setTotalAmountSelf(state, total_amount_self){
      state.total_amount_self = total_amount_self
    },
    setTotalAmountCompany(state, total_amount_company){
      state.total_amount_company = total_amount_company
    },
    setQuotationItems(state, quotation_items){
      state.quotation_items = quotation_items
    },
    setQuotationItemsSelf(state, quotation_items_self){
      state.quotation_items_self = quotation_items_self
    },
    setProducts(state, products){
      state.products = products
    },
    setProducts2(state, products2){
      state.products2 = products2
    },
    setCement(state, cement){
      state.cement = cement
    },
    setStoneDust(state, stone_dust){
      state.stone_dust = stone_dust
    },
    setMaterials(state, materials){
      state.materials = materials
    },
    setCementPrice(state, cement_cem_IV_price){
      state.cement_cem_IV_price = cement_cem_IV_price
    },
    setCementCem2Price(state, cement_cem_II_price){
      state.cement_cem_II_price = cement_cem_II_price
    },
    setStoneDustPrice(state, stone_dust_price){
      state.stone_dust_price = stone_dust_price
    },
    setSandPrice(state, sand_price){
      state.sand_price = sand_price
    },
    setAccess(state, access){
      state.access = access
      state.isAuthenticated = true
    },
    setRefresh(state, refresh){
      state.refresh = refresh
    },
    setUsername(state, username){
      state.username = username
      state.isAuthenticated = true
      localStorage.setItem('username', state.username)
    },
    removeAccess(state) {
      state.access = ''
      state.isAuthenticated = false
    },
    removeUsername(state){
      state.username = ''
      state.isAuthenticated = false
    },
    setName(state, name){
      state.name = name
      localStorage.setItem('name', state.name)
    },
    setEmail(state, email){
      state.email = email
      localStorage.setItem('email', state.email)
    },
    setPhone(state, phone_contact){
      state.phone_contact = phone_contact
      localStorage.setItem('phone_contact', state.phone_contact)
    },
    setCompany(state, company_name){
      state.company_name = company_name
      localStorage.setItem('company_name', state.company_name)
    },
    setMobilisationCost(state, mobilisation_cost){
      state.mobilisation_cost = mobilisation_cost
    },
    setLocationName(state, location_name){
      state.location_name = location_name
    },
    setLocationDistance(state, location_distance){
      state.location_distance = location_distance
    },
    setTotalQuantity(state, total_quantity){
      state.total_quantity = total_quantity
    },
    setCementQuantity(state, cement_quantity){
      state.cement_quantity = cement_quantity
    },
    setCementIIQuantity(state, cement_II_quantity){
      state.cement_II_quantity = cement_II_quantity
    },
    setStoneQuantity(state, stone_quantity){
      state.stone_quantity = stone_quantity
    },
    setCementTotalCost(state, cement_total_cost){
      state.cement_total_cost = cement_total_cost
    },
    setCementIITotalCost(state, cement_II_total_cost){
      state.cement_II_total_cost = cement_II_total_cost
    },
    setStoneTotalCost(state, stone_total_cost){
      state.stone_total_cost = stone_total_cost
    },
    setProjects(state, projects){
      state.projects = projects
    },
    setBlocks(state, blocks){
      state.blocks = blocks
    },
    setBlocksMade(state, blocks_made){
      state.blocks_made = blocks_made
    },
    setInvoiceNumber(state, invoice_number){
      state.invoice_number = invoice_number
    },
    addToCart(state, item){
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)
      if (exists.length){
        exists[0].quantity = /*parseInt(exists[0].quantity) + */parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }
    },
    addToMatCart(state, item){
      const exists = state.matcart.items.filter(i => i.material.id === item.material.id)
      if (exists.length){
        exists[0].quantity = parseInt(item.quantity)
      } else {
        state.matcart.items.push(item)
      }
      localStorage.setItem('matcart', JSON.stringify(state.matcart))
    },
    clearMatCart(state){
      state.matcart = { items: []}
      localStorage.setItem('matcart', JSON.stringify(state.matcart))
    },
    setIsLoading(state, status){
      state.isLoading = status
    },
    clearCart(state) {
      state.cart = { items: [] }
      state.planter = []
      localStorage.setItem('cart', JSON.stringify(state.cart))
      state.total_quantity = 0 
  },
  clearTotal(state){
    state.cart = { items: [] }
    localStorage.setItem('cart', JSON.stringify(state.cart))
    state.total_quantity = 0
  }
  },
  actions: {
    fetchAllProducts(context){
      return axios.get('/api/v1/products/')
                  .then(response => {
                    context.commit("setProducts", response.data)
                  })
                  .catch((err) => console.error(err));
    },
    fetchAllProducts2(context){
      return axios.get('/api/v1/products2/')
                  .then(response => {
                    context.commit("setProducts2", response.data)
                  })
                  .catch((err) => console.error(err));
    },
    fetchCement(context){
      return axios.get('/api/v1/cement/')
                  .then(response => {
                    context.commit("setCement", response.data)
                  })
                  .catch((err) => console.error(err));
    },
    fetchStoneDust(context){
      return axios.get('/api/v1/stone-dust/')
                  .then(response => {
                    context.commit("setStoneDust", response.data)
                  })
                  .catch((err) => console.error(err));   
    },
    fetchAllMaterials(context){
      return axios.get('/api/v1/materials/')
        .then(response => {
          context.commit("setMaterials", response.data)
        })
        .catch((err) => console.error(err));
    },
    fetchCementPrice(context){
      return axios.get('/api/v1/materials/1/')
                  .then(response => {
                    context.commit("setCementPrice", response.data.price)
                  })
                  .catch((err) => console.error(err));
    },
    fetchCementIIPrice(context){
      return axios.get('/api/v1/materials/4/')
                  .then(response => {
                    context.commit("setCementIIPrice", response.data.price)
                  })
                  .catch((err) => console.error(err));
                },            
    fetchStoneDustPrice(context){
      return axios.get('/api/v1/materials/2/')
                  .then(response => {
                    context.commit("setStoneDustPrice", response.data.price)
                  })
                  .catch((err) => console.error(err));
    },
    fetchSandPrice(context){
      return axios.get('/api/v1/materials/3/')
                  .then(response => {
                    context.commit('setSandPrice', response.data.price)
                  })
                  .catch((err) => console.error(err));
    },
    
    setupOfflineMode(context, targetPath){
      context.commit('setOnlineStatus', false)
      if (history.pushState) history.pushState(null, 'Connection Error', targetPath)
    },
    addToTotal(context, payload){
      const total_quantity = context.state.total_quantity
      total_quantity += payload
      context.commit('UPDATE_TOTAL', total_quantity)
    }
  },
  modules: {
  }
})
