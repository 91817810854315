<template>
    <li>
        <div class="">
            <div class="has-text-left">
                <a v-bind:href="document.file">{{document.name}}</a>
                
            </div>
        </div>
    </li>
</template>

<script>
//import VuePdfEmbed from 'vue-pdf-embed'

    export default {
        name: 'Document',
        props:{
            document: Object
        },

    }
</script>

<style scoped>
    
</style>