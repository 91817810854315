<template>
<div>
    <TopBar />
        <vueper-slides ref="myVueperSlides" :parallax="parallax" :parallax-fixed-content="parallaxFixedContent" fixed-height="500px" >
        <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :title="slide.title"
            :content="slide.content"
            :image="slide.image">
            <template #content>
                <div class="vueperslide__content-wrapper parallxFixedContent has-text-white" style="background-color:rgba(0,0,0,0.5);">
                    <span style="font-size:36px">{{slide.title}}</span>
                    <span style="font-size:22px">{{slide.content}}</span>

                </div>
            </template>
            
        </vueper-slide>
        </vueper-slides>
    
    <div class="section pb-2">
        <div class="section-title">
            <router-link to ='/materials-calculator'>
                <a class="button is-success" @click="clearCart()" style="background-color:#004100;">Go to Materials Calculator</a>
            </router-link>
        </div>
    </div>

    <div class="section pt-4">
        <div class="section-title">
            <router-link to="/materials">
                <a class="button is-success" style="background-color:#004100;">Purchase Materials</a>
            </router-link>
        </div>
    </div>
    

    <div class="section">
        <div class="section-title">
            <h2 class=""><strong>How it Works</strong></h2>
        </div>
        <div class="row">
            <div class="column is-4 box m-2 icon-box">
                <span class="icon mb-2"><i class="fa fa-building" aria-hidden="true" style="color:white"></i></span>
                <div class="content">
                    <h4>Estimate materials needed</h4>
                    <p>We provide you with a very simple tool to help you
                        estimate how much material you may need for your project.
                    </p>
                </div>
            </div>
            <div class="column is-4 box m-2 icon-box">
                <span class="icon mb-2"><i class="fa fa-list-alt" aria-hidden="true" style="color:white"></i></span>
                <div class="content">
                    <h4>Get a quotation</h4>
                    <p>We generate a free draft quotation based on your material needs. 
                        We further support you to review your plan for an accurate quotation.
                    </p>
                </div>
            </div>
            <div class="column is-4 box m-2 mb-4 icon-box">
                <span class="icon mb-2"><i class="fa fa-industry" aria-hidden="true" style="color:white"></i></span>
                <div class="content">
                    <h4>Kick off Project</h4>
                    <p>Tofali operators will inspect and execute your project and will have all updates to show progress of your projects.</p>
                </div>
            </div>
        </div>
        <div class="row" style="margin-bottom: 40px;"> 
            <article class="message is-primary">
                        <div class="message-body">
                            <h4 class="mb-1"><strong>Contact Us</strong> </h4>
                            <p>For any inquiries contact us on +256 393240090 or tofali@ecoconcrete.co.ug</p>
                            <a href="https://wa.me/256700744250"><i class="icofont-brand-whatsapp"></i>  Send a message</a>
                        </div>
            </article>
        </div>
        
    </div>    

</div>
    
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import TopBar  from '../components/TopBar.vue'

export default {
    name: 'Index',
    data: () => ({
        parallax: 1,
        parallaxFixedContent: false,
        slides: [
            {
            title: 'Welcome to Tofali Africa',
            content: 'Order for on-site procurement for all your construction needs at your convenience.',
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1653478568/t9di9gttcruybx2ljpmm.jpg'
            },
            {
            title: 'Save up to 20% with Tofali Africa',
            content: 'Make Concrete blocks on-site with Tofali Africa. Save up to 20% and avoid getting cheated.',
            image: 'https://res.cloudinary.com/celestialmk/image/upload/v1653478763/xxdcfayopd2tfxefvcrx.jpg'
            }
  ]
}),
    components: {
        VueperSlide,
        VueperSlides,
        TopBar
    },
    methods: {
        clearCart(){
            this.$store.commit('clearCart')
        },
    }
}
</script>

<style scoped>
@media  (min-width: 769px ) {
    .row {
        display: flex;
    }   
}
.slider-overlay{
    background-color: rgba(0,0,0,0.5);
}
.section-title h2 {
    font-size: 22px;
    line-height: 28px;
    margin: 0;
    color: #004100;
    text-align: center;
}
.is-success{
    background-color:#004100;
}
.icon-box .icon {
    display: inline-block;
    left: 0;
    top: 0;
    text-align: center;
    height: 60px;
    width: 60px;
    line-height: 58px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -moz-background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-size: 25px;
    background-color: #004100;
}

.planet-section {
     background-image: url('https://res.cloudinary.com/celestialmk/image/upload/v1660030547/yb26wz2eyn5vttkmxegc.jpg');
     background-position: center;
}
.planet-section .overlay{
 padding: 40px 0;
 background-color: rgba(86, 212, 86, 0.28);
 text-align: center;
 background-repeat: center;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media only screen and (min-width: 320px) and (max-width: 567px){
.overlay-section .overlay {
    padding: 40px 0;
}}

.overlay-section{
    background-image: url('https://res.cloudinary.com/celestialmk/image/upload/v1660030547/ufjdsbwhs8czxldica3e.jpg');
    background-position: center;
}
.overlay-section .overlay {
    padding: 90px 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.overlay-section .overlay h2{
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
}
</style>