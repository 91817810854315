<template>
    <div class="navigation">
          <div class="m-4">
          <h1><span><i @click="goBack()" class="icofont-rounded-left pr-2"></i></span><strong>{{ name }}</strong></h1>
          </div>
    </div>
    </template>
    
    <script>
    export default{
        name: 'Back',
        props:{
            name: String,       
        },
        methods:{
            goBack(){
                this.$store.commit('clearCart')
                //this.history.back()
                this.$router.push('/materials-calculator')
            }
        }
    }
    </script>
    
    <style scoped>
    .navbar-brand {
      min-height: 3.25rem;
      display: flex;
    }
    
    .navigation {
      width :100%;
       z-index :100;
       position :fixed;
       line-height: 1rem;
       top:  0;
       height : 3.25rem;
       background-color: #ffffff;
       opacity :0.8rem;
       left: 0;
       border-bottom : .02rem solid #eee;
       display: flex;
       font-size :1.5rem;
    }
    
    
    
    </style>