<template>
        <tr v-if="item.quantity > 0">
            <td class=""><strong>{{ item.product.name}} {{item.product.type}}</strong></td>
            <td>{{ item.quantity }}</td>
            <td v-if="this.$store.state.cement_cem_II_price != ''">{{ getItemPrice(item).toLocaleString()}}</td>
            <td v-else><div class="loader"></div></td>
            <td v-if="this.$store.state.cement_cem_II_price != ''" class="">{{ getItemPriceTotal(item).toLocaleString()}}</td>
            <td v-else><div class="loader"></div></td>
            <!--<td class="pr-0"><button class="delete" @click="removeFromCart(item)"></button></td>-->
        </tr>    
</template>

<script>
    export default {
        name: 'CartItem',
        props: {
            initialItem: Object
        },
        data(){
            return {
                item: this.initialItem,
                labour_price:'',
                cart: {
                    items:[]
                }
            }
        },
        mounted(){
            this.cart = this.$store.state.cart
        },

        methods:{
            getItemPrice(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil(item.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    ))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil(item.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    ))
                } else {
                    return (Math.ceil(item.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    ))
                }
            },
            getItemPriceTotal(item){
                if(parseInt(this.$store.state.location_distance) <= 15){
                    return (Math.ceil( item.quantity * (item.product.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * parseInt(this.$store.state.location_distance) 
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    )))
                } else if(parseInt(this.$store.state.location_distance) > 15 && parseInt(this.$store.state.location_distance) <= 150){
                    return (Math.ceil( item.quantity * (item.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * parseInt(this.$store.state.location_distance)
                    )))
                } else {
                    return (Math.ceil(item.quantity * (item.material_cost + item.product.base_labour
                    + 4 * item.product.productivity_factor * 15
                    + item.product.productivity_factor * 150
                    )))
                }
            },
            getCementTotal(item){
                return Math.ceil(item.quantity / item.product.cement)
            },
            getStoneTotal(item){
                return Math.ceil(item.quantity / item.product.stone_dust)
            },
            getCementPrice(item){
                return Math.ceil(((item.quantity / item.product.cement)) * parseInt(this.$store.state.cement_price))
            },
            getStoneDustPrice(item){
                return (((item.quantity / item.product.stone_dust).toFixed(1)) * parseInt(this.$store.state.stone_dust_price))
            },
            
            updateCart(){
                localStorage.setItem('cart', JSON.stringify(this.$store.state.cart))
            },
            removeFromCart(item){
                this.$emit('removeFromCart', item)

                this.updateCart()
            },
        }

    }



</script>

<style scoped>
 td{
    padding: 5px;
    border:1px solid #000;
}

.loader {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #004100; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>