<template>
    <div class="section">
        <h1 class="title">Customer information</h1>
        <h2>latest posts</h2>
         <div class="create-post">
            <label for="create-post">say something</label>
            <input type="text" id="create-post" v-model="text"
            placeholder="Create a post">
            <button class="is-success" v-on:click="createPost">Post</button>
         </div>
        <hr>
        <p class="error" v-if="error">{{ error }}</p>
        <div
            v-for="(post, index) in posts" 
            v-bind:item="post"
            v-bind:index="index"
            v-bind:key="post._id"
            v-on:dblclick="deletePost(post._id)"
        >
        <p>{{post.text}}</p>
        {{`${post.createdAt.getDate()}/${post.createdAt.getMonth()}/
        ${post.createdAt.getFullYear()}` }}
        </div>
    </div>
</template>

<script>
import PostService from '@/PostService'

    export default{
        name: 'PostInfo',
        data(){
            return {
                posts:[],
                error:'',
                text:''
            }
        },
        async created(){
            try{
                this.posts = await PostService.getPosts();
            } catch(err){
                this.err = err.message;
            }
        },
        methods:{
            async createPost(){
                await PostService.insertPost(this.text);
                this.posts = await PostService.getPosts();
            },
            async deletePost(id){
                await PostService.deletePost(id);
                this.posts = await PostService.getPosts();
            }
        }
        
    }
</script>

<style scoped>

</style>