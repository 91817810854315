<template>
    <div class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
    <header class="modal-card-head">
    <p class="modal-card-title">Materials MarketPlace</p>
    <button class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
        <table class="table">
            <thead>
                <tr>
                    <th>Material</th> 
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
            <tr class="cementMaterial">
                <td><strong>Cement:</strong></td>
                
                <td>
                <a @click="decrementCement()">-</a>
                <input type="number" class="input" name="" id="" v-model="cement_quantity" @change="getCementTotal()">
                
                <a @click="incrementCement()">+</a>
                </td>
                <td>{{CementCost.toLocaleString()}}</td>
                <td>{{this.cement_total_cost.toLocaleString()}}</td>
                <!--<td style="border: 0px"><button class="delete" id="getCementMaterialItem" @click="removeCementMaterial()"></button></td>-->
            </tr>
            
            <tr class="stoneDustMaterial">
                <td><strong>Stone Dust:</strong></td>
                
                <td>
                    <a @click="decrementStone()">-</a>
                    <input type="number" class="input" v-model="stone_quantity" @click="getStoneDustTotal()">
                    
                    <a @click="incrementStone()">+</a>
                </td>
                <td>{{StoneDustCost.toLocaleString()}}</td>
                <td>{{this.stone_total_cost.toLocaleString()}}</td>
                <!--<td style="border: 0px"><button class="delete" id="getStoneDustMaterialItem" @click="removeStoneDustMaterial()"></button></td>-->
            </tr>
            <!--

                <tr class="sandMaterial">
                    <td><strong>Sand: tonnes</strong></td>
                    <td>
                        <a @click="decrementStone()">-</a>
                        <input type="number" class="input" v-model="sand_quantity" @click="getSandTotal()">
                        
                        <a @click="incrementStone()">+</a>
                    </td>
                    <td>{{SandCost.toLocaleString() }}</td>
                    <td>{{ this.sand_total_cost.toLocaleString() }}</td>
                </tr>
            -->
                <tr style="background-color:#D9D9D9">
                    <th colspan="4">Material Cost</th>
                <td class="totalMaterialCost">{{(this.cement_total_cost + this.stone_total_cost).toLocaleString()}}</td>
            </tr>
            </tbody>
        </table>

        <div class="box">
            <h3>Order details</h3>
            

            <p>Delivery Address</p>
        </div>
    </section>
    <footer class="modal-card-foot">
    <button class="button is-warning close">Cancel</button>
    <button class="button is-success">Place Order</button>
    </footer>
    </div>
</div>

</template>

<script>
export default {
    name: 'MaterialCartItem',
    components: {
        
    },
    data(){
        return {
            cart: {
                items:[]
            },
            cement_quantity:'',
            stone_quantity:'',
            cement_total_cost:'',
            stone_total_cost:'',
            sand_total_cost:'',

        }
    },
    mounted(){
        this.cart = this.$store.state.cart
        //Obtain total cement quantity
        this.cement_quantity = this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.cement)
        }, 0)
        //set store state of cement quantity
        this.$store.commit('setCementQuantity', this.cement_quantity)

        //Obtain total stone dust quantity
        this.stone_quantity = this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.stone_dust)
        }, 0)
        //set store state of stone dust quantity
        this.$store.commit('setStoneQuantity', this.stone_quantity)

        //Obtain total cement cost
        this.cement_total_cost = parseInt(this.$store.state.cement_cem_IV_price) * this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.cement)
        }, 0)
        this.$store.commit('setCementTotalCost', this.cement_total_cost)
        //Obtain total stone cost
        this.stone_total_cost = parseInt(this.$store.state.stone_dust_price) * this.cart.items.reduce((acc, curVal) => {
            return acc += Math.ceil(curVal.quantity / curVal.product.stone_dust)
        }, 0)
        this.$store.commit('setStoneTotalCost', this.stone_total_cost)
        //Obtain total sand cost
        

        setInterval(() => {  //update the total material price on quantity change
            this.getCementTotal()
            this.getStoneDustTotal()
            //this.getSandTotal()
        }, 500)
  },
    computed: {
    CementCost(){
            return this.$store.getters.cement_price
        },
    StoneDustCost(){
        return this.$store.getters.stone_dust_price
    },
    },
    methods:{
        decrementCement(){
            this.cement_quantity -= 1
            this.cement_total_cost -= parseInt(this.$store.state.cement_price)
            this.$store.commit('setCementQuantity', this.cement_quantity)
            this.$store.commit('setCementTotalCost', this.cement_total_cost)
        },
        incrementCement(){
            this.cement_quantity += 1
            this.cement_total_cost += parseInt(this.$store.state.cement_price)
            this.$store.commit('setCementQuantity', this.cement_quantity)
            this.$store.commit('setCementTotalCost', this.cement_total_cost)  
        },
        decrementStone(){
            this.stone_quantity -= 1
            this.stone_total_cost -= parseInt(this.$store.state.stone_dust_price)
            this.$store.commit('setStoneQuantity', this.stone_quantity)
            this.$store.commit('setStoneTotalCost', this.stone_total_cost)
        },
        incrementStone(){
            this.stone_quantity += 1
            this.stone_total_cost += parseInt(this.$store.state.stone_dust_price)
            this.$store.commit('setStoneQuantity', this.stone_quantity)
            this.$store.commit('setStoneTotalCost', this.stone_total_cost)
        },
        getCementTotal(){
            if (isNaN(this.cement_quantity) || this.cement_quantity < 1){
                this.cement_quantity = 0
            }
                this.cement_total_cost = parseInt(this.cement_quantity * this.$store.state.cement_cem_IV_price)
                this.$store.commit('setCementQuantity', this.cement_quantity)
                this.$store.commit('setCementTotalCost', this.cement_total_cost)
        },
        getStoneDustTotal(){
            if (isNaN(this.stone_quantity) || this.stone_quantity < 1){
                this.stone_quantity = 0
            }
                this.stone_total_cost = parseInt(this.stone_quantity * this.$store.state.stone_dust_price)
                this.$store.commit('setStoneQuantity', this.stone_quantity)
                this.$store.commit('setStoneTotalCost', this.stone_total_cost)
        }
}
}
</script>

<style scoped>
th, td{
    padding: 4px
}
</style>