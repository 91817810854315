<template>
<div>
  <div class="section pt-3">
  
      <TopName name="Projects"/>
      
      <template v-if="this.$store.state.projects != ''">
        <ul v-for="(project, index) in this.$store.state.projects">
          <div class="box mb-2 has-text-left">
            <h2 class="is-size-4"><strong>{{project.name }}</strong></h2>
            <h3 class="is-size-5 has-text-weight-medium">Project Status: {{ project.status }}</h3>
            <p><span class="is-size-5"><strong>Financial Progress</strong></span></p>
          <p class="is-size-5 has-text-weight-medium">{{ (project.amount_paid).toLocaleString() }} of {{ (project.project_cost).toLocaleString() }} UGX</p>
          <progress class="progress is-primary" :value="project.amount_paid / project.project_cost * 100" max="100">
          </progress>
          <p class="is-size-5">{{ Math.ceil(project.amount_paid / project.project_cost * 100) }}% Complete </p>
        <hr class="m-2">
        <h3 class="is-size-5"><strong>Material Total Progress</strong></h3>
          <span class="is-size-5 has-text-weight-medium"><strong>Materials Received of Required</strong></span>
          <p><span class="is-size-5"><strong>Cement (CEM-IV)</strong></span></p>
          <p class="is-size-5 has-text-weight-medium">{{ project.cement_used }} of {{ project.cement_total }} bags</p>
          <progress class="progress is-primary" :value="project.cement_used / project.cement_total * 100" max="100">
          </progress>
          
          <template v-if="project.cement_cem2_total >=1">
            <p><span class="is-size-5"><strong>Cement (CEM-II)</strong></span></p>
            <p class="is-size-5 has-text-weight-medium">{{ project.cement_cem2_used }} of {{ project.cement_cem2_total }} bags</p>
            <progress class="progress is-primary" :value="project.cement_cem2_used / project.cement_cem2_total * 100" max="100">
            </progress>
            <p class="is-size-5">{{ Math.ceil(project.cement_cem2_used / project.cement_cem2_total * 100) }}% delivered </p>
          </template>

        <hr class="m-2">
          <span class="is-size-5"><strong>Stone Dust</strong></span>
          <p class="is-size-5 has-text-weight-medium">{{ project.stone_dust_used }} of {{ project.stone_dust_total }} tonnes</p>
          <progress class="progress is-primary" :value="project.stone_dust_used / project.stone_dust_total * 100" max="100">
          </progress>
          <p class="is-size-5">{{ Math.ceil(project.stone_dust_used / project.stone_dust_total * 100) }}% delivered </p>

        <hr class="m-2">
        <h3 class="is-size-5"><strong>Material Used</strong></h3>
          <span class="is-size-5"><strong>Cement (CEM-IV) Used</strong></span>
          <p class="is-size-5 has-text-weight-medium">{{ project.cement_used_stock }} of {{ project.cement_in_stock }} bags</p>
          <progress class="progress is-primary" :value="project.cement_used_stock / project.cement_in_stock * 100" max="100">
          </progress>
          <p class="is-size-5">{{ Math.ceil(project.cement_used_stock / project.cement_in_stock * 100) }}% used </p>

          <template v-if="project.cement_cem2_total >= 1">
            <span class="is-size-5"><strong>Cement (CEM-II) Used</strong></span>
            <p class="is-size-5 has-text-weight-medium">{{ project.cement_cem2_used_stock }} of {{ project.cement_cem2_in_stock }} bags</p>
            <progress class="progress is-primary" :value="project.cement_cem2_used_stock / project.cement_cem2_in_stock * 100" max="100">
            </progress>
            <p class="is-size-5">{{ Math.ceil(project.cement_cem2_used_stock / project.cement_cem2_in_stock * 100) }}% used </p>
          </template>
        <hr class="m-2">
        <!--
          <span class="is-size-5"><strong>Stone Dust used of In Stock</strong></span>
          <p class="is-size-5 has-text-weight-medium">{{ project.stone_dust_used_stock }} of {{ project.stone_dust_in_stock }} tonnes</p>
          <progress class="progress is-primary" :value="project.stone_dust_used_stock / project.stone_dust_in_stock * 100" max="100">
          </progress>
          <p class="is-size-5">{{ Math.ceil(project.stone_dust_used_stock / project.stone_dust_in_stock * 100) }}% used </p>
        -->
          <span class="is-size-5"><strong>Blocks Production</strong></span>
            <li v-for="(block, index) in this.$store.state.blocks.filter(block => block.project === project.id)">
              <span class="is-size-5 has-text-weight-medium">{{ block.name }}</span> 
              <p class="is-size-5">{{ block.number }} of {{ block.number_required }}</p>
              <progress class="progress is-success" :value="block.number / block.number_required * 100" max="100">
                </progress>
                <p class="is-size-5">{{ Math.ceil(block.number / block.number_required * 100) }}% Complete </p>
                <hr class="m-2">
                <div class="columns is-12 is-mobile">
                  <div class="column is-10" @click="toggleContentBlock">
                  <p>Daily production details</p>
                  </div>
                  <div class="arrow-container column is-2" @click="toggleContentBlock">
                    <span class="arrow-icon" :class="{ 'up': showContentBlock, 'down': !showContentBlock }"></span>
                  </div>
                </div> 
                <div class="control content" v-show="showContentBlock">
                  <table class="table" v-if="this.$store.state.blocks_made != ''">
                    <thead>
                      <th>Quantity</th>
                      <th>Date</th>
                    </thead>
                    <tr v-for="block_made in this.$store.state.blocks_made.filter(block_made => block_made.block === block.id)" :key="block_made.id">
                      <td>{{ block_made.quantity }}</td>
                      <td><span class="pr-4">{{(block_made.date_created).substring(0,10)}}</span> {{ (block_made.date_created).substring(11, 19) }}</td>
                    </tr>
                  </table>
                  <div v-else>
                    <DailyLoading />
                  </div>
                </div>
                <hr class="">
            </li>
              </div>
          </ul>
      </template>
      <template v-else>
      <ProjectLoading description="Active projects are shown here"/>
      </template>
    
      <!--<button class="is-success button" @click="makePayment()">Make Payment</button>-->

  </div>
</div>   
</template>

<script>
import axios from 'axios'
import TopName from '@/components/TopName.vue';
import { mapActions } from 'vuex';
import ProjectLoading from '@/components/ProjectLoading.vue'
import DailyLoading from '@/components/DailyLoading.vue';
import firebase from 'firebase/app'
import { getMessaging, getToken, onMessage} from "firebase/messaging";
import { initializeApp } from "firebase/app";
import 'firebase/app'
import 'firebase/messaging'
import { toast } from 'bulma-toast'


export default{
    name: 'Projects',
    components:{
    TopName,
    ProjectLoading,
    DailyLoading
},
    data() {
    return {
      projects: [],
      record:[],
      blocks: [],
      history:[],
      projectsWithBlocks: [],
      projectBlocks:[],
      showContentBlock: false,
    };
  },
  computed(){
    const projects = this.$store.state.projects
    const blocks = this.$store.state.blocks

    

    this.projectBlocks = projects.map((project) => ({
      ...project,
      blocks: blocks.filter(block => block.project === project.id)
    })
    
    )
  },
  mounted(){
    setInterval(() => {
      this.getProjects()
      this.getBlocks()
      this.getBlocksMade()
    }, 2000)


/*
    const messaging = firebase.messaging(app)

    messaging.usePublicVapidKey("BFI6NcEHPyo2qk5MSpuIFIqVlYAqShzOy8won8tZ506IDa1gP5DLKyT2LSpwT1JqqaRnxAZjiprbFTzvody7sIc")

    messaging.requestPermission().then(() => {
      console.log('Notification permission granted.')
      messaging.getToken().then((token) => {
        console.log('New token created: ', token)
        this.saveNotificationToken(token)
      })
    }).catch((err) => {
      console.log('Unable to get permission to notify.', err)
    })

    messaging.onTokenRefresh(function () {
      messaging.getToken().then(function (newToken) {
        console.log('Token refreshed: ', newToken)
        this.saveNotificationToken(newToken)
      }).catch(function (err) {
        console.log('Unable to retrieve refreshed token ', err)
      })
    }) */
  },
  created() {
    this.getProjects();
    this.getBlocks();
  },
    methods:{
    //...mapActions(["getProjects"]),
    //...mapActions(["getBlocks"]),
    
    getProjects(){
      axios.get('/api/v1/projects/')
            .then(response => {
              //console.log(response.data)
              this.$store.commit("setProjects", response.data)
            })
            .catch((err) => console.error(err));
    },
    getBlocks(){
      axios
          .get('/api/v1/blocks/')
          .then(response => {
            //console.log(response.data)
            this.$store.commit("setBlocks", response.data)       
          })
          .catch((err) => console.error(err));
    },
    getBlocksMade(){
      axios
          .get('/api/v1/blocks_made')
          .then(response => {
            //console.log(response.data)
            this.$store.commit("setBlocksMade", response.data)
          })
          .catch((err) => console.error(err));
    },
    generateReference(){
            let date = new Date()
            return date.getTime().toString();
    },

    toggleContentBlock(){
        this.showContentBlock = !this.showContentBlock;
      },

    makePayment(){
      const inputBody = {
      "client_id": "ccefcbfd-0f26-493a-af34-0b72bd16c339",
      "client_secret": "aZ@gj9e56AhsvQr",
      "grant_type": "client_credentials"
      };
const headers = {
  'Content-Type':'application/x-www-form-urlencoded',
  'Accept':'*/*'
};

fetch('https://openapiuat.airtel.africa/auth/oauth2/token',
{
  method: 'POST',
  body: inputBody,
  headers: headers
})
.then(function(res) {
    
    return res.json();
    
}).then(function(body) {
    console.log(body);
});

  }
}
}
</script>

<style scoped>
td{
  padding-left: 2px;
  padding-right: 2px;
}


.arrow-icon {
  display: inline-block;
  border: solid black;
  border-width: 0 3px 3px 0;
  padding: 3px;
  margin-right: 10px;
  transform: rotate(45deg);
  transition: transform 0.2s ease-in-out;
}

.arrow-icon.up {
  transform: rotate(-135deg);
}

.arrow-icon.down {
  transform: rotate(45deg);
}

</style>

