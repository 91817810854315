<template>
    <div>
    <TopName name="Material Details" />
    <template v-if="material != ''">
        <div class="section pt-2 page-product">
            <div class="columns is-multiline">
                <div class="column is-6">
                    <div class="grorg-1">
                    <figure class="mb-1">
                        <img v-bind:src="material.image">
                    </figure>
                    </div>
    
                    <h1 class="title">{{ material.name }}</h1>
    
                    <p>{{ material.description }}</p>
                  
                </div>
    
                <div class="column is-3">
                    <h2 class="subtitle">Information</h2>
    
                    <p><strong>Price: </strong>UGX {{ material.price }} per {{ material.unit }}</p>
    
                    <div class="field has-addons mt-6">
                        <div class="control">
                            <input type="number" class="input" min="1" v-model="quantity">
                        </div>
    
                        <div class="control">
                            <a class="button has-text-white" style="background-color: #004100" @click="addToCart()">Add to cart</a>
                        </div>
                    </div>
                </div>
                <p style="margin-bottom: 50px;">
                    <span>
                    <router-link to="/materials">
                        <a class="button p-1 m-1 has-text-white" style="background-color: #004100">View Other Materials</a>
                    </router-link>
                    </span>
                    <span>
                    <router-link to="/matcart">
                        <a class="button p-1 m-1 has-text-white" style="background-color: #004100">Go to Materials Cart</a>
                    </router-link>
                    </span>
                        
                </p>
            </div>
        </div>
    </template>
    <template v-else>
        <MaterialLoading />
    </template>
</div>
</template>
    


<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import TopName from '@/components/TopName.vue'
import MaterialLoading from '@/components/MaterialLoading.vue'

       
export default {
    name: 'Material',
    data() {
    return {
        material: [],
        quantity: 1,
    }
    },
    components:{
        TopName,
        MaterialLoading
    },
    mounted() {
    this.getMaterial()
    },
    methods: {
    async getMaterial() {
        //this.$store.commit('setIsLoading', true)
        const material_slug = this.$route.params.material_slug

        await axios
            .get(`/api/v1/materials/${material_slug}`)
            .then(response => {
                this.material = response.data

                document.title = this.material.name + ' | Tofali Africa Materials'
            })
            .catch(error => {
                console.log(error)
            })
        
        //this.$store.commit('setIsLoading', false)
    },
    addToCart() {
        if (isNaN(this.quantity) || this.quantity < 1) {
            this.quantity = 1
        }

        const item = {
            material: this.material,
            quantity: this.quantity
        }

        this.$store.commit('addToMatCart', item)

        toast({
            message: 'The material was added to the cart',
            type: 'is-success',
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: 'bottom-right',
        })
    },
    goBack(){
        this.$router.go(-1)
    },
    }
}
</script>

<style scoped>
.page-product{
    padding: 30px;
}
.pt-1{
    padding: 4px 10px 10px;
}
</style>