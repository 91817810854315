<template>
    <TopName name="Set New Password" />

     <div class="section">
        <form @submit.prevent="submitPassword">
            <h3 class="is-size-5 is-pulled-left">Password Reset</h3>
            <input type="text" class="input mt-2" placeholder="New password" v-model="new_password">
            <input type="text" class="input mt-2" placeholder="Password Reset Email" v-model="re_new_password">
            <button class="is-success button mt-2">Submit</button>

        </form>
    </div> 
</template>

<script>
import TopName from '@/components/TopName.vue';
import axios from 'axios';
import { toast } from 'bulma-toast'
export default {
    name: 'ResetConfirm',
    data(){
        return{
            new_password: '',
            re_new_password: ''
        }
    },
    components: {
        TopName
    },
    methods:{
        submitPassword(){
            const data = {
                uid: this.$route.params.uid,
                token: this.$route.params.token,
                new_password: this.new_password,
                re_new_password: this.re_new_password
            }

            axios
                .post('api/v1/users/reset_password_confirm/', data)
                .then(response => {
                    console.log('password changed successfully')
                    this.$router.push('/')
                    toast({
                    message: 'Password has successfully been changed',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'bottom-right',
                })
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')   
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
}
</script>