<template>
    <TopName name="Password Reset Email" />

    <div class="section">
        <form @submit.prevent="submitEmail">
            <h3 class="is-size-5 is-pulled-left">Email</h3>
            <input type="email" class="input" placeholder="Password Reset Email" v-model="email">
            <button class="is-success button mt-2">Submit Email</button>

        </form>
    </div> 

</template>

<script>
import TopName from '@/components/TopName.vue';
import axios from 'axios';
import { toast } from 'bulma-toast'
export default{
    name: "ResetEmail",
    data(){
        return{
            email: '',
        errors: []
        }
    },
    components: {
        TopName
    },
    mounted() {
        document.title = 'Reset Email | Tofali Africa'
    },
    methods: {
        submitEmail(){
            //console.log(this.email)
            axios.defaults.headers.common["Authorization"] = ''

            const data = {
                email : this.email
            }
            axios
                .post('/api/v1/users/reset_password/', data)
                .then(response => {
                    //console.log('email sent successfully')
                    this.$router.push('/')
                    toast({
                    message: 'Password reset email sent successfully',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 4000,
                    position: 'bottom-right',
                })
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                    } else {
                        this.errors.push('Something went wrong. Please try again')   
                        console.log(JSON.stringify(error))
                    }
                })
        }
    }
    
}
</script>